<template>
  <div class="support">
    <div class="title">
      我的留言
    </div>
    <div>
      <support-list :listData="tsjyDta"></support-list>
    </div>
    <div v-if="tsjyDta.length>0" class="pagination">
      <el-pagination
          :current-page="current"
          :page-size="size"
          :page-sizes="[10, 20, 30, 40]"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <not-found v-if="tsjyDta.length == 0"></not-found>
  </div>
</template>
<script>
import supportList from '@/components/supportList'
import breadCrumb from '@/components/common/breadCrumb'
import notFound from '@/components/notFound'
import {getComplaintAndAdvice} from "../../../api/personService";

export default {
  components: {
    breadCrumb, supportList, notFound
  },
  data() {
    return {
      tsjyDta: [],
      current: 0,
      size: 10,
      total: 0,
      lx: '',
    }
  }, mounted() {
    this.lxClick('1')

  }, methods: {
    handleSizeChange(val) {
      this.size = val
      this.getTsxx()
    },
    handleCurrentChange(val) {
      this.current = val
      this.getTsxx()
    },
    lxClick(lx) {
      this.current = 0
      this.getTsxx()
    },

    /**
     * 查询
     */
    getTsxx() {
      const data = {
        start: this.current,
        size: this.size
      }
      getComplaintAndAdvice(data).then(res => {
        let data = res.data
        for (let i = 0; i < data.length; i++) {
          if (data[i].wtlx === '投诉') {
            data[i].steps = [{status: '已发起'}, {status: '已受理'}, {status: '待回复'}]
            data[i].step = parseInt(data[i].zt) + 1;

            if (data[i].zt ==='3') {
              data[i].steps = [{status: '已发起'}, {status: '已拒绝'}]
              data[i].step = 2
            } else if (data[i].zt ==='2') {
              data[i].steps = [{status: '已发起'},{status: '已受理'}, {status: '已回复'}]
              data[i].step = 3
            }else if (data[i].zt ==='0') {
              data[i].steps = [{status: '已发起'},{status: '待受理'}, {status: '待回复'}]
            }
          } else {
            let mc = '待回复'
            if(data[i].zt ==='2'){
              mc = '已回复'
            }


            data[i].steps = [
              {
                status: '已发起',
              },
              {
                status: mc,
              }
            ]
            data[i].step = parseInt(data[i].zt);
          }

          data[i].dialogVisible = false;
        }
        this.tsjyDta = data;
        this.total = res.total;
      })
    }
  }
}
</script>
<style lang="scss">
.pagination {
  text-align: center;
  margin: 20px 0;

  .el-pagination {
    font-weight: 500;
  }
}

.support {
  padding: 20px 20px;
  box-sizing: border-box;
  min-height: 440px;

  .title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;

  }
}

// .hot-tabs {
//     border: none;
//     box-shadow: none;

//     .el-tabs__header {
//         margin: 20px 0 6px;
//         border-bottom: none;
//         background-color: transparent;

//         .el-tabs__item {
//             border: none;
//             font-size: 16px;
//             padding: 0 10px !important;
//             color: #808080;
//             height: 30px;
//             line-height: 30px;
//         }

//         .el-tabs__item.is-active {
//             color: #fff;
//             background: rgba(3, 155, 229, 1) !important;
//         }

//         .el-tabs__item:not(.is-active):hover {
//             color: #808080;
//         }

//         .el-tabs__active-bar {
//             display: none;
//         }
//     }

//     .el-tabs__content {
//         padding: 0 !important;
//     }

//     .el-tabs__nav-wrap::after {
//         display: none;
//     }
// }
</style>
